import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled, { css } from "styled-components"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

import { PageHeader, Layout, MetaData, CommunityLibCard } from "../components"
import { BoxedContainer, Section, ToggleButton } from "../elements"
import { Select } from "../elements/FormElements"
import theme from "../styles/theme"

import logo from "../images/community-lib/library-icon.svg"
import { ChevronDown } from "@styled-icons/feather/ChevronDown"

const CommunityLibraryPage = ({ data, location }) => {
  const paginationSize = 15
  const [allPosts, setAllPosts] = useState(data.posts.edges) // Original posts
  const [posts, setPosts] = useState(allPosts) // Filtered posts
  const [renderedPosts, setRenderedPosts] = useState(
    posts.slice(0, paginationSize)
  ) // Currently renderend posts

  const [category, setCategory] = useState("all")
  const [postType, setPostType] = useState("all")
  const [language, setLanguage] = useState("all")

  const [pagination, setPagination] = useState(1)

  const [isHiddenCategoriesVisible, setHiddenCategoriesVisible] = useState(
    false
  )
  const [featuredCategories, setFeaturedCategories] = useState([])
  const [hiddenCategories, setHiddenCategories] = useState([])

  useEffect(() => {
    // Format posts structure to make filtering easier
    let allPostsFormatted = allPosts.map(post => {
      let tags = post.node.tags
      let tagsFormatted = tags.map(tag => {
        return tag.slug
      })

      // Set default language as English
      if (
        !tagsFormatted.includes("hash-language-french") &&
        !tagsFormatted.includes("hash-language-spanish")
      ) {
        tagsFormatted.push("hash-language-english")
      }

      post.node["tagsFormatted"] = tagsFormatted
      return post
    })
    setAllPosts(allPostsFormatted)

    // Extract categories and sort them based on total posts tagged with it
    let allCategories = []
    allPosts.forEach(post => {
      let tags = post.node.tags
      for (let i = 1; i < tags.length; i++) {
        if (tags[i].visibility === "public") {
          let loc = allCategories.findIndex(cat => cat.slug === tags[i].slug)
          if (loc >= 0) {
            allCategories[loc].count++
          } else {
            allCategories.push({
              ...tags[i],
              count: 1,
            })
          }
        }
      }
    })
    allCategories.sort((a, b) =>
      a.count > b.count ? -1 : b.count > a.count ? 1 : 0
    )
    setFeaturedCategories(allCategories.splice(0, 5))
    setHiddenCategories(allCategories)
  }, [])

  const changeCategory = (slug: string) => {
    setCategory(slug)
    filterPosts(slug, postType, language)
  }

  const changePostType = e => {
    setPostType(e.target.value)
    filterPosts(category, e.target.value, language)
  }

  const changeLanguage = e => {
    setLanguage(e.target.value)
    filterPosts(category, postType, e.target.value)
  }

  const loadMore = () => {
    setRenderedPosts(posts.slice(0, (pagination + 1) * paginationSize))
    setPagination(pagination + 1)
  }

  const filterPosts = (category: string, type: string, language: string) => {
    let filteredPosts = allPosts

    // Filter by category
    if (category !== "all") {
      filteredPosts = filteredPosts.filter(post => {
        return post.node.tagsFormatted.includes(category)
      })
    }

    // Filter by type i.e. video or article
    if (type !== "all") {
      if (type === "video") {
        filteredPosts = filteredPosts.filter(post => {
          return post.node.tagsFormatted.includes("hash-video")
        })
      } else {
        filteredPosts = filteredPosts.filter(post => {
          return !post.node.tagsFormatted.includes("hash-video")
        })
      }
    }

    // Filter by language
    if (language !== "all") {
      filteredPosts = filteredPosts.filter(post => {
        return post.node?.tagsFormatted?.includes(`hash-language-${language}`)
      })
    }

    setPosts(filteredPosts)
    setRenderedPosts(filteredPosts.slice(0, paginationSize))
    setPagination(1)
  }

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.currentPage,
        }}
      />
      <PageHeader
        title="Community Library"
        logo={logo}
        backgroundImage={data.heroBg}
      />

      <Filters>
        <FiltersWrapper>
          <Categories>
            <Visible>
              <Category
                active={category === "all"}
                onClick={() => changeCategory("all")}
              >
                All
              </Category>
              {featuredCategories.map(cat => (
                <Category
                  active={category === cat.slug}
                  onClick={() => changeCategory(cat.slug)}
                  key={cat.slug}
                >
                  {cat.name}
                </Category>
              ))}
              {hiddenCategories.length > 0 && (
                <ViewAllCat
                  title="View All Categories"
                  onClick={() =>
                    setHiddenCategoriesVisible(!isHiddenCategoriesVisible)
                  }
                  active={isHiddenCategoriesVisible}
                >
                  <ChevronDown size={22} strokeWidth={2} />
                </ViewAllCat>
              )}
            </Visible>

            <Hidden visible={isHiddenCategoriesVisible}>
              {hiddenCategories.map(cat => (
                <Category
                  active={category === cat.slug}
                  onClick={() => changeCategory(cat.slug)}
                  key={cat.slug}
                >
                  {cat.name}
                </Category>
              ))}
            </Hidden>
          </Categories>

          <div>
            <StyledSelect onChange={changePostType}>
              <option value="all">All types</option>
              <option value="article">Article</option>
              <option value="video">Video</option>
            </StyledSelect>
            <StyledSelect onChange={changeLanguage}>
              <option value="all">All languages</option>
              <option value="english">English</option>
              <option value="french">French</option>
              <option value="spanish">Spanish</option>
            </StyledSelect>
          </div>
        </FiltersWrapper>
      </Filters>

      <Main withPadding>
        <BoxedContainer>
          {posts.length > 0 ? (
            <Posts>
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 700: 1, 900: 2, 1024: 3 }}
              >
                <Masonry>
                  {renderedPosts.map(post => (
                    <CommunityLibCard key={post.node.id} data={post} />
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </Posts>
          ) : (
            <strong
              style={{
                margin: "0 auto",
                display: "block",
                textAlign: "center",
                opacity: 0.7,
              }}
            >
              No posts available
            </strong>
          )}

          {pagination < Math.ceil(posts.length / paginationSize) && (
            <LoadMore>
              <button
                type="button"
                className="button--primary button--extra-padding button--with-shadow"
                onClick={loadMore}
              >
                Load More
              </button>
            </LoadMore>
          )}
        </BoxedContainer>
      </Main>
    </Layout>
  )
}

export default CommunityLibraryPage

export const query = graphql`
  query {
    currentPage: ghostPage(slug: { eq: "community-library" }) {
      ...PageMetaFields
    }

    posts: allGhostPost(
      filter: { primary_tag: { slug: { eq: "community-library" } } }
      sort: { order: DESC, fields: [published_at] }
    ) {
      edges {
        node {
          ...GhostPostCardFields
          childHtmlRehype {
            htmlAst
          }
        }
      }
    }

    heroBg: file(relativePath: { eq: "community-lib/hero-bg.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 90)
      }
    }
  }
`

const Main = styled(Section.Container)`
  background-color: ${theme.colors.background.light};
  padding: 60px 0;
`

const Filters = styled.div`
  padding: 15px 0;
  box-shadow: ${theme.shadows.darker};
  border-bottom: 1px solid #e2e2e2;
  position: relative;
`

const FiltersWrapper = styled(BoxedContainer)`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${theme.breakpointsObj.md}) {
    flex-direction: column;
    align-items: center;
  }
`

const Categories = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${theme.breakpointsObj.md}) {
    margin: 0 0 10px;
  }
`

const Visible = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${theme.breakpointsObj.md}) {
    justify-content: center;
  }
`

const Hidden = styled.div<{
  visible: boolean
}>`
  display: ${p => (p.visible ? "flex" : "none")};
  flex-wrap: wrap;
  max-width: 700px;

  @media (max-width: ${theme.breakpointsObj.md}) {
    justify-content: center;
  }
`

const ViewAllCat = styled.button<{
  active: boolean
}>`
  color: ${theme.colors.text.base};
  border: solid 1px ${theme.colors.borders.darker};
  height: 35px;
  margin: 10px 10px 10px 0;
  background: #fff;
  border-radius: 17.5px;
  transition: transform 0.2s ease-out;

  &:hover {
    background: ${theme.colors.primary.base};
    color: #fff;
    border-color: ${theme.colors.primary.base};
  }

  ${p =>
    p.active &&
    css`
      transform: rotate(180deg);
    `}
`

const Category = styled(ToggleButton)`
  margin: 10px 10px 10px 0;
`

const StyledSelect = styled(Select)`
  font-size: 1.4rem;
  font-weight: 500;
  color: #5d6474;
  margin: 3px 0 0 15px;
`

const Posts = styled.div`
  margin: -12px;
`

const LoadMore = styled.div`
  text-align: center;
  margin: 40px 0 0;
`
