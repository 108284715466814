import styled from "styled-components"

import arrow from "../images/icons/chevron-down.svg"
import theme from "../styles/theme"

const Select = styled.select`
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${theme.colors.borders.base};
  background-color: #fff;
  padding: 10px 40px 10px 15px;
  height: 48px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(${arrow});
  background-size: 20px;
  background-position: calc(100% - 12px) 50%;
  background-repeat: no-repeat;
`

export { Select }
